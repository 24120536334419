<!--
 * @Author: lbh
 * @Date: 2022-06-14 09:24:56
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-08 14:20:21
 * @Description: 頭部
-->
<template>
  <div
    class="header-box"
    :data-lang="nowL.code"
    ref="big-header"
    :class="{isTop:isTop,noTop:!isTop}"
  >
    <div class="big-box">
      <!-- logo 區域 -->
      <div class="logo-box">
        <img
          :src="configs.logo"
          class="logo"
        />
      </div>
      <!-- 操作區域 -->
      <div class="menu-box">
        <div
          class="menu"
          v-for="(item, index) in configs.menus"
          :key="index"
          :class="{ active: active == item.go && item.go != '' }"
        >
          <div
            v-if="item.child.length == 0"
            class="title"
            @click="$util.route.go(item.go)"
          >
            <a :href="`${origin}${item.go}`"> {{ item[`${nowL.code}title`] || item.title }}</a>
          </div>
          <el-dropdown
            v-else
            placement="bottom"
            @command="$util.route.go"
            :hide-timeout="500"
          >
            <span class="el-dropdown-link">
              {{ item[`${nowL.code}title`] || item.title }}
              <div></div>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              class="header-item"
            >
              <el-dropdown-item
                v-for="(ite, ind) in item.child"
                :key="ite.index"
                :command="ite.go"
                :class="{ active_: active == ite.go }"
              >
                <a :href="`${origin}${ite.go}`">{{ ite[`${nowL.code}title`] || ite.title }}</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- 全屏廣告 -->
      <div
        v-if="configs.showFullScreen"
        class="full-box"
        @click="showFullDialogEvent"
        :style="{
          '--fullBg1Start':configs.fullBg1Start,
          '--fullBg1End':configs.fullBg1End,
          '--fullBg2Start':configs.fullBg2Start,
          '--fullBg2End':configs.fullBg2End,
          '--fullFontColor':configs.fullFontColor,
          '--fullFontSize':configs.fullFontSize
          }"
      >
        <!-- 圖片 -->
        <!-- ../assets/default/4c35d0c88de926c19ac0777969ae859f.gif -->
        <img
          class="full-img"
          :src="configs.fullGif"
        />
        <!-- 背景 -->
        <div class="full-bg"></div>
        <div class="full-body">
          <div
            class="title"
            v-html="configs[`${nowL.code}fullScreenTitle`] || configs.fullScreenTitle"
          >
          </div>
        </div>
      </div>
      <!-- 語言選擇 -->
      <div
        v-if="language.length > 0"
        class="language-box"
      >
        <el-dropdown @command="changeLanguage">
          <span class="el-dropdown-link">
            {{nowL.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in language"
              :key="item.code"
              :command="item"
              :class="{'language-box__active':item.code == nowL.code}"
            >{{item.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="px-mini-box">
      <img
        :src="configs.logo"
        class="logo"
      />

      <img
        class="vector"
        srcset="../assets/icon/Vector@2x.png"
        @click="drawer = true"
      />
    </div>
    <!-- pc 平板 懸浮操作 -->

    <div class="px-fixed-box pc-ttb">
      <div
        v-if="showGoEdit"
        class="log"
        style="color:#FFF;font-size:16px;"
        @click="goEditEvent"
      >
        返回<br />編輯
      </div>
      <div
        v-if="configs.showWhatsApp"
        class="log whatsapp"
        @click="onTap(configs.whatsAppUrl)"
      >
        <img src="../assets/do-img/whatsapp.png" />
        <div class="text">Whatsapp</div>
      </div>
      <div
        v-if="configs.showTop"
        v-show="onTop"
        class="log"
        :style="{backgroundColor:configs.showTopColor}"
        @click="goTop"
      >
        <img src="../assets/do-img/TOP.png" />
        <div class="text">TOP</div>
      </div>
      <!-- 全屏廣告 -->
      <div
        @click="showFullDialogEvent"
        class="full-start"
      >
        <img src="../assets/default/Group 20841 1@2x.png" />
      </div>
    </div>
    <!-- 移動 -->
    <div class="px-fixed-box phone-ttb">

      <!-- 全屏廣告 -->
      <div
        v-if="configs.showFullScreen"
        @click="showFullDialogEvent"
        class="full-start"
      >
        <img src="../assets/default/Group 20841 1@2x.png" />
      </div>
      <div
        v-if="configs.showWhatsApp"
        class="log1"
        @click="onTap(configs.whatsAppUrl)"
      >
        <img src="../assets/icon/WhatsApp@2x.png" />
        <div>{{$tT('whatsAppContactUs','聯絡我們')}}</div>
      </div>
      <!-- <div
        v-if="configs.showTop"
        v-show="onTop"
        class="log2"
        @click="goTop"
      >
        <img src="../assets/do-img/TOP.png" />
      </div> -->

    </div>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="rtl"
      size="calc(100vw / 1.18)"
      :modal-append-to-body="false"
      :before-close="handleClose"
      custom-class="px-drawer"
    >
      <el-collapse
        v-model="activeNames"
        @change="handleChange"
      >
        <template v-for="(item, index) in configs.menus">
          <div
            v-if="item.child.length == 0"
            class="title"
            :class="{ active: active == item.go }"
            @click="clickGo(item.go)"
          >
            <a :href="`${origin}${item.go}`">{{ item[`${nowL.code}title`] || item.title }}</a>
          </div>
          <el-collapse-item
            v-else
            :title="item[`${nowL.code}title`] || item.title"
            name="1"
          >
            <div
              v-for="(ite, ind) in item.child"
              :key="ite.index"
              class="title c-title"
              :class="active == ite.go ? 'active_' : ''"
              @click="clickGo(ite.go)"
            >
              <a :href="`${origin}${ite.go}`">{{ ite[`${nowL.code}title`] || ite.title }}</a>
            </div>
          </el-collapse-item>
        </template>
        <el-collapse-item
          v-if="language.length > 0"
          title="切換語言"
          name="2"
        >
          <div
            v-for="(ite, ind) in language"
            :key="ite.code"
            class="title c-title"
            :class="nowL.code == ite.code ? 'active_' : ''"
            @click="changeLanguage(ite,'t')"
          >
            <div>{{ ite.name }}</div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>

  </div>
</template>

<script>
import { TweenMax } from "gsap";
import ScrollTrigger from '@terwanerik/scrolltrigger';
const trigger = new ScrollTrigger();
export default {
  name: 'self-header',
  props: {
    configs: {
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      active: '',
      onTop: true,
      // 菜單
      drawer: false,
      activeNames: [],
      origin: '/',
      language: [],
      nowL: {
        code: 'HK',
        name: '繁體'
      },
      isTop: true,
      showFullDialog: false
    };
  },
  computed: {
    showGoEdit () {
      return window.location.href.includes('/preview/');
    },
    isEdit () {
      let isEdit = this.$store.getters.getIsEdit
      return isEdit
    }
  },
  watch: {
    $route (o, n) {
      this.getActive();
    },
    configs () {
      this.getActive();
    },
  },
  mounted () {
    window.onscroll = () => {
      if (window.scrollY == 0) {
        this.isTop = true;
      } else {
        this.isTop = false;
      }
    }

    this.$idb.get('language').then(language => {
      if (this.$route.name != 'menu') {
        for (let i = 0; i < language.length; i += 1) {
          if (!language[i].isShow) {
            language.splice(i, 1)
            i -= 1;
          }
        }
      }
      this.language = language;
      let now_language = this.$storage.get('now_language')
      if (!now_language) now_language = 'HK';
      for (let i = 0; i < language.length; i += 1) {
        if (language[i].code == now_language) {
          this.nowL = language[i]
          break;
        }
      }
    })

    this.getActive();
    let origin = location.origin;
    if (origin.includes('gingersoft.com')) {
      this.origin = '/';
    } else {
      this.origin = '/official/';
    }



  },
  methods: {
    // 修改當前語言
    changeLanguage (item, t) {
      this.$storage.set('now_language', item.code)
      this.nowL = item;
      this.$changeLang.togger();
      if (t == 't') {
        this.drawer = false;
      }
    },
    getActive () {
      // * 判斷是否需要展示全屏廣告
      if (this.configs.showFullScreen) {
        if (!this.isEdit) {
          // 排除 /menu
          if (location.pathname.includes('/menu')) {
            console.log('!排除');
          } else {
            let isShowFull = this.$store.getters.getIsShowFull;
            let search = location.href.includes('?t=1')
            if (!isShowFull && !search) {
              // * 設置一次刷新 只有一次提示
              this.$store.commit('setIsShowFull', true);
              setTimeout(() => {
                this.showFullDialogEvent();
              }, 1000);
            }
          }
        }
      }
      let pathname = location.pathname;
      if (pathname.includes('products')) {
        this.active = 'products/' + this.$route.params.arg;
      } else {
        let arg = this.$route.params.arg;
        if (!arg) arg = '首頁';
        this.active = arg;
      }
      let active = this.active;
      let configs = this.configs;
      let menus = configs.menus;
      jump: for (let i in menus) {
        let menu = menus[i];
        if (menu.child && menu.child.length > 0) {
          let child = menu.child;
          for (let j in child) {
            if (active == child[j].go) {
              this.activeNames = [i];
              break jump;
            }
          }
        }
      }
    },
    goTop () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    onTap (url) {
      window.open(url || 'https://api.whatsapp.com/send/?phone=85265666460&text=%E6%88%91%E6%83%B3%E4%BA%86%E8%A7%A3POS%E9%A4%90%E9%A3%B2%E6%96%B9%E6%A1%88%E3%80%82');
    },
    goEditEvent () {
      this.$confirm('是否前往編輯此頁面?', '溫馨提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$store.commit('setIsEdit', true);
        this.$router.push({
          name: 'edit',
          params: {
            arg: this.$route.params.arg,
          },
        });
      }).catch(() => {

      });

    },
    clickGo (go) {
      this.drawer = false;
      this.$util.route.go(go);
    },
    handleClose (done) {
      done();
    },
    handleChange (val) {
      console.log(val);
    },

    // * 顯示全屏廣告
    showFullDialogEvent () {
      this.$fullScreenAd(this.configs.fullScreen)
    }
  },
};
</script>
<style lang="less" >
.language-box__active {
  color: var(--themeColor);
}
</style>
<style lang="less" scoped>
.header-box {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.06);
  transition: 0.3s;
  --menuColor: #666;
  &.noTop {
    --menuColor: #666 !important;
  }
  &.isTop {
    background-color: transparent;
    box-shadow: none;
  }
  a {
    text-decoration: none;
    pointer-events: none;
    color: var(--menuColor);
  }
  & /deep/ .el-collapse-item__header {
    border: none;
  }
  .big-box {
    display: flex;
    height: 80px;
    padding: 0 100px;
    position: relative;
    .logo-box {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      left: 100px;
      top: 0;
      .logo {
        height: calc(100% - 34px);
      }
    }
    .menu-box {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      .menu {
        height: 100%;
        display: flex;
        margin-left: 35px;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--menuColor);
        cursor: pointer;
        a {
          text-decoration: none;
          color: var(--menuColor);
        }
        &:hover {
          a {
            color: var(--themeColor) !important;
          }
        }
        & /deep/ .el-dropdown-link {
          display: flex;
          align-items: center;
          &:hover {
            color: var(--themeColor) !important;
            div {
              background-image: url("../assets/icon/Vector-5.png");
            }
          }
          div {
            width: 11px;
            height: 6px;
            margin-left: 4px;
            background-image: url("../assets/icon/Vector-4.png");
            background-repeat: no-repeat;
          }
        }
        &.active {
          a {
            color: var(--themeColor) !important;
          }
          position: relative;
          & /deep/ .el-dropdown-link {
            color: var(--themeColor) !important;
            div {
              background-image: url("../assets/icon/Vector-5.png");
            }
          }
          &::after {
            position: absolute;
            width: 24px;
            height: 3px;
            background: var(--themeColor);
            opacity: 1;
            content: "";
            bottom: 10px;
            left: calc(50% - 12px);
            border-radius: 4px;
          }
        }
        & /deep/ .el-dropdown {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: inherit !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--menuColor);
          .el-dropdown-link {
            font-size: inherit !important;
            img {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .full-box {
      // background-color: var(--themeColor);
      // font-size: var(--font20);
      // font-family: PingFang SC, PingFang SC;
      // font-weight: 500;
      // color: #ffffff;
      // padding: var(--font8) var(--font16);
      align-self: center;
      // border-radius: var(--font4);
      user-select: none;
      cursor: pointer;
      position: absolute;
      right: var(--font200);
      .full-img {
        position: absolute;
        width: var(--font80);
        z-index: 6;
        top: -34px;
        left: -54px;
      }
      .full-bg {
        height: var(--font32);
        position: absolute;
        background: linear-gradient(
          180deg,
          var(--fullBg2Start, #d11743) 0%,
          var(--fullBg2End, #dc2413) 100%
        );
        left: calc(var(--font14) * -1);
        right: calc(var(--font14) * -1);
        top: 50%;
        transform: translateY(-50%);
        &::before {
          content: "";
          width: var(--font22);
          height: var(--font22);
          background: linear-gradient(
            135deg,
            var(--fullBg2Start, #d11743) 0%,
            var(--fullBg2End, #dc2413) 100%
          );
          position: absolute;
          transform: translateY(-50%) rotateY(45deg) rotateZ(45deg);
          top: 50%;
          transform-origin: center;
          left: calc(var(--font11) * -1);
          border-bottom-left-radius: var(--font4);
        }
        &::after {
          content: "";
          width: var(--font22);
          height: var(--font22);
          background: linear-gradient(
            135deg,
            var(--fullBg2Start, #d11743) 0%,
            var(--fullBg2End, #dc2413) 100%
          );
          position: absolute;
          transform: translateY(-50%) rotateY(45deg) rotateZ(45deg);
          top: 50%;
          transform-origin: center;
          right: calc(var(--font11) * -1);
          border-top-right-radius: var(--font4);
        }
      }
      .full-body {
        height: var(--font40);
        background: linear-gradient(
          180deg,
          var(--fullBg1Start, #fffdd7) 0%,
          var(--fullBg1End, #ffc876) 100%
        );
        position: relative;
        .title {
          position: relative;
          z-index: 4;
          font-size: var(--fullFontSize, var(--font20));
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: var(--fullFontColor, #35220b);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0 var(--font20);
        }

        &::before {
          content: "";
          width: var(--font28);
          height: var(--font28);
          background: linear-gradient(
            135deg,
            var(--fullBg1Start, #fffdd7) 0%,
            var(--fullBg1End, #ffc876) 100%
          );
          position: absolute;
          transform: translateY(-50%) rotateY(45deg) rotateZ(45deg);
          top: 50%;
          transform-origin: center;
          left: calc(var(--font14) * -1);
          border-bottom-left-radius: var(--font4);
        }
        &::after {
          content: "";
          width: var(--font28);
          height: var(--font28);
          background: linear-gradient(
            135deg,
            var(--fullBg1Start, #fffdd7) 0%,
            var(--fullBg1End, #ffc876) 100%
          );
          position: absolute;
          transform: translateY(-50%) rotateY(45deg) rotateZ(45deg);
          top: 50%;
          transform-origin: center;
          right: calc(var(--font14) * -1);
          z-index: 1;
          border-top-right-radius: var(--font4);
        }
      }
    }
    .language-box {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      & /deep/ .el-dropdown {
        color: var(--menuColor);
        font-size: var(--font20);
      }
      .active {
        color: var(--themeColor);
      }
    }
  }
  .px-mini-box {
    display: flex;
    font-size: 20px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      height: 30px;
      margin-left: 15px;
    }
    .vector {
      width: 24px;
      margin-right: 17px;
    }
  }
  & /deep/ .el-drawer__wrapper {
    top: 44px;
    height: calc(100% - 44px);
    .px-drawer {
      border-radius: 10px 0 0 10px;
      .el-collapse {
        border: none;
      }
      .title {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        position: relative;
        a {
          color: #000000;
        }
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background: #b8bec5;
        }
        &.active {
          color: var(--themeColor);
          a {
            color: var(--themeColor);
          }
        }
      }
      .c-title {
        padding-left: 30px;
        &.active_ {
          color: var(--themeColor);
          a {
            color: var(--themeColor);
          }
        }
      }
      .el-collapse-item__header {
        padding-left: 20px;
        height: 50px;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 20px;
          right: 20px;
          height: 1px;
          background: #b8bec5;
        }

        .el-collapse-item__arrow {
          margin-right: 20px;
          font-size: 16px;
        }
      }
      .el-collapse-item {
        .is-active {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse-item__content {
        padding: 0;
      }
    }
  }
  & /deep/ .v-modal {
    top: 44px;
    height: calc(100% - 44px);
  }
  .whatsapp {
    background-color: rgb(80, 205, 94) !important;
  }
}
@media screen and (max-width: 1000px) {
  .header-box {
    background-color: #fff !important;
    position: sticky !important;
    .big-box {
      display: none;
    }
    .px-mini-box {
      display: flex;
      .px-fixed-box {
        position: fixed;
        right: 20px;
        bottom: 148px;
        .log {
          margin-top: 10px;
          img {
            width: 60px;
            vertical-align: middle;
          }
        }
      }
    }
    & /deep/ .px-drawer {
      width: calc(100vw / 1.48) !important;
      .el-drawer__header {
        margin-bottom: 0;
        padding: 11px 20px;
      }
    }
  }
  .px-fixed-box {
    &.phone-ttb {
      display: none;
      .full-start {
        position: fixed;
        right: 20px;
        bottom: 150px;
        z-index: 50;
        animation: quickLtoR 1s infinite linear;
        img {
          width: 70px;
        }
      }
    }
    &.pc-ttb {
      position: fixed;
      right: 20px;
      bottom: 148px;
      .full-start {
        position: fixed;
        left: 20px;
        bottom: 150px;
        z-index: 50;
        animation: quickLtoR 1s infinite linear;
        img {
          width: 70px;
        }
      }
      .log {
        margin-bottom: 2px;
        background-color: var(--themeColor);
        border-radius: 6px;
        width: 70px;
        height: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 26px;
        }
        .text {
          margin-top: 6px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .header-box {
    .big-box {
      display: none;
    }
    .px-mini-box {
      display: flex;
    }
    & /deep/ .px-drawer {
      width: calc(100vw / 1.18) !important;
    }
  }
  .px-fixed-box {
    &.phone-ttb {
      display: block;
      position: fixed;
      right: 4px;
      bottom: 100px;

      .log1 {
        background: linear-gradient(180deg, #f3c879 0%, #f5b640 100%);
        padding: 0 2px;
        border-radius: 6px;
        height: auto;
        // font-size: 0%;
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 20px;
        position: relative;
        width: 114px;
        img {
          width: 50px;
          vertical-align: middle;
          margin-bottom: 2px;
          position: absolute;
          top: -3px;
          left: -5px;
        }
        div {
          margin-left: 50px;
          margin-right: 10px;
          font-size: 100%;
          width: calc(100% - 60px);
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .log2 {
        margin-top: 5px;
        background-color: var(--themeColor);
        border-radius: 6px;
        font-size: 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
        img {
          width: 30px;
        }
      }
    }
    &.pc-ttb {
      display: none;
    }
  }
}
@media screen and (min-width: 1000px) {
  .header-box {
    .big-box {
      display: flex;
    }
    .px-mini-box {
      display: none;
    }
  }
  .px-fixed-box {
    &.phone-ttb {
      display: none;
    }
    &.pc-ttb {
      position: fixed;
      right: 0;
      bottom: 130px;
      .full-start {
        display: none;
      }
      .log {
        margin-bottom: 2px;
        background-color: var(--themeColor);
        border-radius: 6px;
        width: 70px;
        height: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 26px;
        }
        .text {
          margin-top: 6px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
</style>
